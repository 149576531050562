import { BsSortDown, BsSortUpAlt } from "react-icons/bs";
import { FaEye, FaRegEdit, FaSort } from "react-icons/fa";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import GlobalFilter from "../components/search";

import { useState } from "react";
import { CSVLink } from "react-csv";
import {
  TbPlayerPlay,
  TbPlayerTrackNext,
  TbPlayerTrackPrev,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/button";
import MemeTokenModal from "./MemeTokenModal";

export default function Table(props) {
  const [showModal, setShowModal] = useState(false);
  const [memeId, setMemeId] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const tableInstance = useTable(
    {
      columns: props.columns,
      data: props.data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    state,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  const getMemeTokenById = async (id) => {
    setMemeId(id);
    setShowModal(() => true);
  };
  const formatTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  };
  const dataWithFormattedTimestamp = props.data.map((item) => ({
    ...item,
    formatted_date_added: formatTimestamp(item.date_added),
  }));

  const csvInvestmentParticipants = [
    {
      label: "Wallet Address",
      key: "txn_participant_address",
    },
    {
      label: "Token Name",
      key: "token_name",
    },

    {
      label: "Token Amount",
      key: "txn_token_amount",
    },
    {
      label: "Transaction hash",
      key: "txn_hash",
    },
    {
      label: "TimeStamp",
      key: "formatted_date_added",
    },
  ];

  return (
    <>
      <div className={`${showModal === true ? "blur-lg" : ""}`}>
        {props.enableSearch && props?.data?.length > 0 ? (
          <div className="flex items-center justify-between mb-5">
            <GlobalFilter value={globalFilter} setValue={setGlobalFilter} />
            <div className="flex items-center gap-4">
              {props.csv === true ? (
                <>
                  <CSVLink
                    filename="Investment.csv"
                    target="_blank"
                    data={dataWithFormattedTimestamp}
                    headers={csvInvestmentParticipants}
                  >
                    <Button design="primary" content="Download All (csv)" />
                  </CSVLink>
                </>
              ) : (
                <></>
              )}

              {pageOptions.length > 1 && (
                <select
                  className="px-2 py-1 bg-white border border-black rounded-lg outline-none cursor-pointer text-zoopad-color"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 15, 20].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize} rows
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        {page.length > 0 && props?.data?.message !== "Invalid Token" ? (
          <>
            <div className="mb-6 overflow-x-auto">
              <table
                {...getTableProps()}
                className="w-full border border-black divide-y divide-black table-auto"
              >
                <thead>
                  {headerGroups?.map((headerGroup, i) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={i}
                      className="text-left divide-x divide-black"
                    >
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="p-3 text-xs text-zoopad-color"
                        >
                          <div className="flex items-center justify-between">
                            <p className="inline">{column.render("Header")}</p>
                            {column.Header !== "Actions" && (
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <BsSortDown size={20} />
                                  ) : (
                                    <BsSortUpAlt size={20} />
                                  )
                                ) : (
                                  <FaSort size={20} fill="#2564AE" />
                                )}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-black"
                >
                  {page?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={i}
                        className="divide-x divide-black cursor-pointer group hover:bg-[#ADADAD]"
                      >
                        {row?.cells?.map((cell, i) => (
                          <td
                            className="p-[6px] overflow-hidden overflow-x-scroll no-scrollbar"
                            {...cell.getCellProps()}
                            key={i}
                          >
                            {cell.column.Header === "Actions" ? (
                              <div className="flex gap-3">
                                {location.pathname === "/dashboard" && (
                                  <div
                                    className="text-lg py-1 px-3 font-medium rounded-lg border border-[#7A7A7A] text-zoopad-color"
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/ido/detail/${row.original._id}`
                                      );
                                    }}
                                  >
                                    <FaEye />
                                  </div>
                                )}

                                {location.pathname === "/memeTokens" && (
                                  <div
                                    className="text-lg py-1 px-3 font-medium rounded-lg border border-[#7A7A7A] hover:text-green-500 text-zoopad-color"
                                    onClick={() => {
                                      if (location.pathname === "/memeTokens") {
                                        getMemeTokenById(row?.original?._id);
                                      }
                                    }}
                                  >
                                    <FaRegEdit />
                                  </div>
                                )}
                              </div>
                            ) : (
                              cell.render("Cell")
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {pageOptions.length > 1 && (
              <div className="flex justify-between px-2 mt-3">
                <div className="flex items-center justify-center gap-8 pt-2 pb-2 text-2xl text-zoopad-color">
                  <div className="p-1 border border-black rounded-lg">
                    <TbPlayerTrackPrev
                      className="cursor-pointer"
                      size={20}
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    />
                  </div>
                  <button
                    className="flex items-center gap-2 p-1 min-w-[150] justify-between text-xl border rounded-lg border-black"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <TbPlayerPlay size={20} className="rotate-180" />
                  </button>
                  <div className="text-lg">
                    <p>
                      Page {pageIndex + 1} of {pageOptions.length}
                    </p>
                  </div>
                  <button
                    className="flex items-center gap-2 p-1 min-w-[150] justify-between text-xl border rounded-lg border-black"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <TbPlayerPlay size={20} />
                  </button>
                  <div className="p-1 border border-black rounded-lg">
                    <TbPlayerTrackNext
                      className="cursor-pointer"
                      size={20}
                      onClick={() => gotoPage(pageOptions.length - 1)}
                      disabled={!canNextPage}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center gap-3 pt-2 pb-2 text-lg text-zoopad-color">
                  <p>Go to Page </p>
                  <input
                    type="tel"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    className="px-3 py-1 text-lg font-normal border border-black rounded-lg w-14 text-zoopad-color focus:outline-none"
                  />
                  <p> of {pageOptions.length}</p>
                </div>
              </div>
            )}

            {/* {showModal !== "" ? (
            <DeleteModal showModal={showModal} setShowModal={setShowModal} />
          ) : null} */}
          </>
        ) : (
          <div className="p-12">
            <p className="text-6xl text-center text-zoopad-color">
              NO DATA FOUND
            </p>
          </div>
        )}
      </div>
      {showModal && (
        <MemeTokenModal
          setShowModal={setShowModal}
          id={memeId}
          memeTokenDependency={props.memeTokenDependency}
        />
      )}
    </>
  );
}
