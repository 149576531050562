import React from "react";
import Button from "./button";
import Modal from "./modal";

export default function LogoutModal(props) {
  return (
    <Modal showModal={props.showModal}>
      <div className="flex flex-col items-center justify-between gap-3 p-10 text-xl font-normal text-center bg-white border border-black rounded-lg text-zoopad-color focus:outline-none">
        <p className="mb-5 text-lg font-semibold">
          Are you sure you want to logout?
        </p>
        <div className="flex gap-7">
          <Button
            content="Logout"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          />
          <Button
            design="error"
            content="Cancel"
            onClick={() => props.showModal(false)}
          />
        </div>
      </div>
    </Modal>
  );
}
