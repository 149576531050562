function Modal(props) {
  return (
    <div
      className="absolute inset-0 z-50 flex items-center justify-center w-screen h-screen"
      onClick={() => {
        props.showModal(false);
      }}
    >
      <div
        className="relative flex flex-col justify-center w-full shadow-lg outline-none focus:outline-none sm:w-fit"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
export default Modal;
