import React, { useEffect, useState } from "react";
import { MemeTokenByIdApi, UpdateMemeTokenByIdApi } from "../apis/tokens";
import ResponseLoader from "./responseLoader";

function MemeTokenModal({ setShowModal, id, memeTokenDependency }) {
  const [isLoading, setIsLoading] = useState(false);
  const [memeTokenById, setMemeTokenById] = useState({});

  useEffect(() => {
    async function fetchMemeToken() {
      setIsLoading(true);
      const accessToken = localStorage.getItem("access_token");
      const result = await MemeTokenByIdApi(accessToken, id);
      if (result?.status === 200) {
        setMemeTokenById(result?.data);
      }
      setIsLoading(false);
    }
    fetchMemeToken();
  }, [id]);

  const handleUpdateMemeToken = async () => {
    const accessToken = localStorage.getItem("access_token");
    const memeToken = {
      _id: memeTokenById?._id,
      meme_token_name: memeTokenById?.meme_token_name,
      meme_token_symbol: memeTokenById?.meme_token_symbol,
      is_active: memeTokenById?.is_active,
      meme_token_address: memeTokenById?.meme_token_address,
    };
    const result = await UpdateMemeTokenByIdApi(accessToken, memeToken);
    if (result?.status === 200) {
      memeTokenDependency((prev) => !prev);
      setShowModal(() => false);
    }
  };

  const changeMemeTokenValue = async (e) => {
    console.log(e);
    setMemeTokenById({ ...memeTokenById, [e.target.name]: e.target.value });
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center">
          <ResponseLoader size={100} />
        </div>
      ) : (
        <>
          <div className="absolute top-1/2 left-1/2 flex gap-6 items-center flex-col -translate-x-1/2 -translate-y-1/2 bg-white px-14 py-20 max-w-lg w-full rounded-xl border-[1px] border-black">
            <input
              type="text"
              className="w-full border-[1px] border-black p-2 rounded-lg"
              placeholder="name"
              name="meme_token_name"
              value={memeTokenById?.meme_token_name}
              onChange={(e) => changeMemeTokenValue(e)}
            />
            <input
              type="text"
              className="w-full border-[1px] border-black p-2 rounded-lg"
              placeholder="symbol"
              name="meme_token_symbol"
              value={memeTokenById?.meme_token_symbol}
              onChange={(e) => changeMemeTokenValue(e)}
            />
            <input
              type="text"
              className="w-full border-[1px] border-black p-2 rounded-lg"
              placeholder="token address"
              name="meme_token_address"
              value={memeTokenById?.meme_token_address}
              onChange={(e) => changeMemeTokenValue(e)}
            />
            <select
              name="is_active"
              className="border-[1px] border-black p-2 rounded-lg w-full"
              onChange={(e) => changeMemeTokenValue(e)}
            >
              <option value={true} selected={memeTokenById?.is_active === true}>
                Active
              </option>
              <option
                value={false}
                selected={memeTokenById?.is_active === false}
              >
                Inactive
              </option>
            </select>
            <div className="flex mt-8 flex-row w-full items-center justify-between">
              <button
                className="bg-green-700 p-2 rounded-lg text-white max-w-[112px] w-full text-sm"
                onClick={handleUpdateMemeToken}
              >
                Save
              </button>
              <button
                className="bg-red-500 p-2 rounded-lg text-white max-w-[112px] w-full text-sm"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MemeTokenModal;
