import axios from "axios";

export async function LoginApi(data) {
  const result = axios
    .post(process.env.REACT_APP_ADMIN_API_URL + "/admin/users/login-user", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}
