import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import ParticipantsTable from "../components/Table";
import { columnsIdoParticipants } from "../data/columns";
import { IdoInvestmentApi } from "../apis/idoApi";
import BackArrow from "../assets/BackArrow.svg";
import ResponseLoader from "../components/responseLoader";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function IdoParticipants() {
  const location = useLocation();
  const projectName = location?.state;
  const [isLoading, setIsLoading] = useState(false);

  const [bfaData, setBfaData] = useState([]);
  const [allocationData, setAllocationData] = useState([]);
  const [claimHistory, setClaimHistory] = useState([]);
  const [refundHistory, setRefundHistory] = useState([]);
  const [investmentDetails, setInvestmentDetails] = useState({});
  const participantsColumns = useMemo(() => columnsIdoParticipants, []);
  const [activeSubHeader, setActiveSubHeader] = useState("BFA");
  const navigate = useNavigate();
  const id = location?.pathname?.split("/")[5];

  useEffect(() => {
    async function fetchInvestments() {
      setIsLoading(true);

      const accessToken = localStorage.getItem("access_token");
      const result = await IdoInvestmentApi(accessToken, id);
      if (result?.status === 200) {
        setBfaData(result?.data?.bfa);
        setAllocationData(result?.data?.allocation);
        setClaimHistory(result?.data?.claim_history);
        setRefundHistory(result?.data?.refund_history);
        setInvestmentDetails(result?.data);
      } else {
        checkIFLogin()
      }
      setIsLoading(false);
    }
    fetchInvestments();
  }, [id]);// eslint-disable-line react-hooks/exhaustive-deps


  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
      localStorage.removeItem("access_token")
       navigate("/signin");
     }
     return error;
    });
  }


  return (
    <Layout>
      {isLoading ? (
        <ResponseLoader size={100} />
      ) : (
        <>
          {" "}
          <img
            src={BackArrow}
            alt="Back"
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <div className="flex flex-col gap-12">
            <h2 className="text-40-600 text-center">{projectName}</h2>
            <div className="flex flex-col gap-5">
              {" "}
              <div className="flex flex-row items-center justify-around ">
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "BFA"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("BFA")}
                >
                  BFA details
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Allocation"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Allocation")}
                >
                  Allocation
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Claim"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Claim")}
                >
                  Claim History
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Refund"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Refund")}
                >
                  Refund History
                </p>
              </div>
              <hr />
              {investmentDetails && (
                <div>
                  <ParticipantsTable
                    columns={participantsColumns}
                    data={
                      activeSubHeader === "BFA"
                        ? bfaData
                        : activeSubHeader === "Allocation"
                        ? allocationData
                        : activeSubHeader === "Claim"
                        ? claimHistory
                        : refundHistory
                    }
                    enableSearch={true}
                    csv={true}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default IdoParticipants;
