import CopySvg from "../assets/copy-line-icon.svg";
import { useState } from "react";

function copyHandler(text, setCopied) {
  setCopied(true);
  navigator.clipboard.writeText(text);
  setTimeout(() => {
    setCopied(false);
  }, 2000);
}
export const columsTokenList = [
  {
    Header: "Token Name",
    accessor: "token_name",
    Cell: (d) => {
      return <p>{d?.value}</p>;
    },
  },
  {
    Header: "Token Adress",
    accessor: "token_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);
      return (
        <div className="flex gap-2 relative w-fit items-center">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          {/* <div className="relative"> */}
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
          {/* </div> */}
        </div>
      );
    },
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: (d) => {
      const dateObject = new Date(d?.value);
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("en-US", options);

      return <div>{formattedDate}</div>;
    },
  },
  {
    Header: "Symbol",
    accessor: "token_symbol",
  },
  {
    Header: "Owner Address",
    accessor: "token_owner_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);

      return (
        <div className="flex gap-2 items-center relative w-fit">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
        </div>
      );
    },
  },
];

export const columsMemeTokenList = [
  {
    Header: "Name",
    accessor: "meme_token_name",
  },
  {
    Header: "Symbol",
    accessor: "meme_token_symbol",
  },
  {
    Header: "Token Address",
    accessor: "meme_token_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);

      return (
        <div className="flex gap-2 w-fit relative  items-center">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
        </div>
      );
    },
  },
  {
    Header: "Status",
    accessor: "is_active",
    Cell: (d) => {
      return <div>{d?.value ? "Active" : "Inactive"}</div>;
    },
  },
  {
    Header: "Actions",
  },
];

export const columnsIdosList = [
  {
    Header: "IDO Name",
    accessor: "ido_name",
  },
  {
    Header: "IDO Address",
    accessor: "ido_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);

      return (
        <div className="flex relative gap-2 w-fit items-center">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
        </div>
      );
    },
  },
  {
    Header: "Start date",
    Cell: (d) => {
      const utcTimestampStart = d?.row?.original?.ido_listing_date;
      const dateObjStart = new Date(utcTimestampStart);

      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedDateStart = dateObjStart.toLocaleDateString(
        "en-US",
        options
      );

      return (
        <div className="flex items-center gap-2">
          <p>{formattedDateStart}</p>
        </div>
      );
    },
  },
  {
    Header: "End Date",
    Cell: (d) => {
      const utcTimestampEnd = d?.row?.original?.ido_end_date;
      const dateObjEnd = new Date(utcTimestampEnd);

      const options = { year: "numeric", month: "short", day: "numeric" };

      const formattedDateEnd = dateObjEnd.toLocaleDateString("en-US", options);

      return (
        <div className="flex items-center gap-2">
          <p>{formattedDateEnd}</p>
        </div>
      );
    },
  },
  {
    Header: "BFA Date",
    accessor: "ido_bfa_end_date",
    Cell: (d) => {
      const dateObject = new Date(d?.value);
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("en-US", options);

      return <div>{formattedDate}</div>;
    },
  },
  {
    Header: "Completion Percentage",
    accessor: "ido_completion_percantage",
  },
  {
    Header: "Actions",
  },
];

export const columnsIdoParticipants = [
  {
    Header: "Token Address",
    accessor: "txn_token_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);

      return (
        <div className="flex gap-2 items-center relative w-fit">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70  ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
        </div>
      );
    },
  },
  {
    Header: "Token Amount",
    accessor: "txn_token_amount",
  },
  {
    Header: "Transaction hash",
    accessor: "txn_hash",
  },
  {
    Header: "Participant Wallet Address",
    accessor: "txn_participant_address",
    Cell: (d) => {
      const [copied, setCopied] = useState(false);

      return (
        <div className="flex gap-2 relative w-fit items-center">
          <p className="w-[80%] overflow-hidden text-ellipsis">{d?.value}</p>
          <img
            src={CopySvg}
            alt="Copy Icon"
            className="w-4 h-4"
            onClick={() => {
              copyHandler(d?.value, setCopied);
            }}
          />
          <p
            className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
              copied === false ? "hidden" : "block"
            }`}
          >
            Copied
          </p>
        </div>
      );
    },
  },
  {
    Header: "TimeStamp",
    accessor: "date_added",
    Cell: (d) => {
      const dateObject = new Date(d?.value);
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("en-US", options);

      return <div>{formattedDate}</div>;
    },
  },
  // {
  //   Header: "Allocated Token",
  //   accessor: "invt_ido_token_allocation_amount",
  // },
];
