import React, { useEffect, useState } from "react";
import { VscLayersActive } from "react-icons/vsc";
import { MdOutlineToken } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { PiListChecksFill } from "react-icons/pi";

import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";
// import appLogo from "../assets/logo.svg";

function Sidebar(props) {
  const [activeHeader, setActiveHeader] = useState("");

  const [dashboard, setDashboard] = useState(false);
  const [tokenList, setTokenList] = useState(false);

  const [balance, setBalance] = useState(false);

  const [memeTokens, setMemeTokens] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/dashboard")[1] === "") {
      setActiveHeader("Dashboard");
    } else if (window.location.pathname.split("/")[1] === "tokenList") {
      setActiveHeader("TokenList");
    } else if (window.location.pathname.split("/")[1] === "memeTokens") {
      setActiveHeader("memeTokens");
    } else if (window.location.pathname.split("/")[1] === "balance") {
      setActiveHeader("balance");
    }
    // else if (window.location.pathname.split("/")[1] === "add-property") {
    //   setActiveHeader("Properties");
    // } else if (window.location.pathname.split("/")[1] === "property") {
    //   setActiveHeader("Property details");
    // } else if (window.location.pathname.split("/")[1] === "user") {
    //   setActiveHeader("User details");
    // }
    else {
      setActiveHeader("Dashboard");
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-between gap-12 text-[#7A7A7A] p-7">
        <div className="flex flex-col items-center w-full gap-3">
          <div
            className="flex flex-row items-center relative"
            onMouseOver={() => setDashboard(true)}
            onMouseOut={() => setDashboard(false)}
          >
            <Link
              to="/dashboard"
              className={`flex rounded-lg items-center justify-start w-full gap-6 px-3 py-2 border border-white cursor-pointer hover:text-zoopad-color transition ease-in-out delay-150  duration-300 ${
                activeHeader === "Dashboard" ? "text-zoopad-color" : ""
              }`}
            >
              <PiListChecksFill size={25} />
              <p
                className={`${
                  dashboard === true
                    ? "block absolute w-full left-full text-xs"
                    : "hidden"
                }`}
              >
                All Ido
              </p>
            </Link>
          </div>
          <div
            className="flex flex-row items-center relative"
            onMouseOver={() => setTokenList(true)}
            onMouseOut={() => setTokenList(false)}
          >
            <Link
              to="/tokenList"
              className={`flex rounded-lg items-center justify-start w-full gap-6 px-3 py-2 border border-white cursor-pointer hover:text-zoopad-color transition ease-in-out delay-150  duration-300 ${
                activeHeader === "TokenList" ? "text-zoopad-color" : ""
              }`}
            >
              <MdOutlineToken size={25} />

              <p
                className={`${
                  tokenList === true
                    ? "block absolute w-full left-full text-xs"
                    : "hidden"
                }`}
              >
                All Tokens
              </p>
            </Link>
          </div>
          <div
            className="flex flex-row items-center relative"
            onMouseOver={() => setBalance(true)}
            onMouseOut={() => setBalance(false)}
          >
            <Link
              to="/balance"
              className={`flex rounded-lg items-center justify-start w-full gap-6 px-3 py-2 border border-white cursor-pointer hover:text-zoopad-color transition ease-in-out delay-150  duration-300 ${
                activeHeader === "balance" ? "text-zoopad-color" : ""
              }`}
            >
              <MdOutlineAccountBalanceWallet size={25} />
              <p
                className={`${
                  balance === true
                    ? "block absolute w-full left-full text-xs"
                    : "hidden"
                }`}
              >
                Balance
              </p>
            </Link>
          </div>
          <div
            className="flex flex-row items-center relative"
            onMouseOver={() => setMemeTokens(true)}
            onMouseOut={() => setMemeTokens(false)}
          >
            <Link
              to="/memeTokens"
              className={`flex rounded-lg items-center justify-start w-full gap-6 px-3 py-2 border border-white cursor-pointer hover:text-zoopad-color transition ease-in-out delay-150  duration-300 ${
                activeHeader === "memeTokens" ? "text-zoopad-color" : ""
              }`}
            >
              <VscLayersActive size={25} />
              <p
                className={`${
                  memeTokens === true
                    ? "block absolute w-full left-full text-xs"
                    : "hidden"
                }`}
              >
                Meme Tokens
              </p>
            </Link>
          </div>
        </div>
        <div
          className="flex items-center justify-center w-full gap-6 pt-2 transition duration-300 ease-in-out delay-150 border-t-2 cursor-pointer  hover:text-red-500"
          onClick={() => props.showModal(true)}
        >
          <FiLogOut size={25} />
        </div>
      </div>
    </>
  );
}

export default Sidebar;
