import React from "react";

const Button = (props) => {
  return (
    <button
      className={
        props.design === "error"
          ? `text-lg py-2 px-3 font-medium rounded-lg border border-[#7A7A7A] text-red-500`
          : props.design === "success"
          ? `text-lg py-2 px-3 font-medium rounded-lg border border-[#7A7A7A] text-green-500`
          : props.design === "login"
          ? `text-lg py-2 px-3 font-medium rounded-lg border border-[#7A7A7A] text-zoopad-color min-w-[250px]`
          : props.design === "form"
          ? `text-lg py-2 px-3 font-medium rounded-lg border border-[#7A7A7A] text-zoopad-color h-full`
          : `text-zoopad-color py-2 px-3 text-[16px] font-medium border border-[#7A7A7A] rounded-lg`
      }
      {...props}
    >
      {props.content}
    </button>
  );
};

export default Button;
