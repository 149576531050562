import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import { columsTokenList } from "../data/columns";
// import { TokenListData } from "../data/data";
import ResponseLoader from "../components/responseLoader";
import TokenListTable from "../components/Table";
import { TokensListApi } from "../apis/tokens";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function TokenList() {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenListsData, setTokenListsData] = useState([]);
  const columns = useMemo(() => columsTokenList, []);
  // const data = useMemo(() => TokenListData, []);
  const navigate = useNavigate()

  const getAllTokens = async () => {
    setIsLoading(true);
    const access_token = localStorage.getItem("access_token");
    const response = await TokensListApi(access_token);
    if (response?.status === 200) {
      setTokenListsData(response?.data);
    }
    // setTokenListsData(response || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTokens();
    checkIFLogin()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
      localStorage.removeItem("access_token")
       navigate("/signin");
     }
     return error;
    });
  }

  return (
    <Layout>
      <div className="flex flex-col gap-5">
        <p className="text-4xl text-zoopad-color"> All Tokens </p>
        {isLoading ? (
          <ResponseLoader size={100} />
        ) : (
          <TokenListTable
            columns={columns}
            data={tokenListsData}
            // data={data}
            enableSearch={true}
          />
        )}
      </div>
    </Layout>
  );
}

export default TokenList;
