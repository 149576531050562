import circularLoader from "../assets/eclipse-loader.svg";
import dotsLoader from "../assets/dot-loader.svg";

function ResponseLoader({ size, type }) {
  return (
    <div style={{ width: `${size}px` }}>
      <img
        src={
          type === "circle"
            ? circularLoader
            : type === "dots"
            ? dotsLoader
            : dotsLoader
        }
        alt="loading..."
      />
    </div>
  );
}

export default ResponseLoader;
