import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Signin from "../pages/signin";
import Dashboard from "../pages/dashboard";
import TokenList from "../pages/tokenList";
import MemeToken from "../pages/memeToken";
import IdoDetail from "../pages/idoDetail";
import Balance from "../pages/balance";
import IdoParticipants from "../pages/idoParticipants";

// import ProtectedRoute from "./protectedRoute";
// import Signin from "../pages/signin";

function PubRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/signin" replace />} />

        <Route
          exact
          path="/signin"
          element={
            <ProtectedRoute fromRoute="signin">
              <Signin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/balance"
          element={
            <ProtectedRoute>
              <Balance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tokenList"
          element={
            <ProtectedRoute>
              <TokenList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/memeTokens"
          element={
            <ProtectedRoute>
              <MemeToken />
            </ProtectedRoute>
          }
        />
        <Route
          path="dashboard/ido/detail/:id"
          element={
            <ProtectedRoute>
              <IdoDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="dashboard/ido/detail/participants/:id"
          element={
            <ProtectedRoute>
              <IdoParticipants />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default PubRoutes;
