import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoutModal from "./logoutModal";
import Sidebar from "./sidebar";

function Layout(props) {
  const [showModal, setShowModal] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="">
        <div className="sticky top-0 z-50 py-5 mx-5 bg-white header-container border-b border-[#7A7A7A]">
          <Link to="/dashboard">
            <h5 className="text-5xl text-[#111135]"> Zoopad </h5>
          </Link>
        </div>
        <div className="flex">
          <div className="sticky top-[89px] flex-none h-full pr-0 sidebar-container">
            <Sidebar showModal={setShowModal} />
          </div>
          <div className="w-full min-h-screen overflow-y-scroll no-scrollbar">
            <div className="p-7 body-container">{props.children}</div>
          </div>
        </div>
      </div>
      {showModal && <LogoutModal showModal={setShowModal} />}
    </>
  );
}

export default Layout;
