import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import { columnsIdosList } from "../data/columns";
import { IdosListApi } from "../apis/idoApi";
import ResponseLoader from "../components/responseLoader";
import IdosTable from "../components/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState([]);
  const [unsuccessful, setUnsuccessful] = useState([]);

  const [promotion, setPromotion] = useState([]);
  const [present, setPresent] = useState([]);
  const [feature, setFeature] = useState([]);
  const [idosList, setIdosList] = useState({});
  const [activeSubHeader, setActiveSubHeader] = useState("Active");
  const columns = useMemo(() => columnsIdosList, []);

  const navigate = useNavigate()
  const getAllTokens = async () => {
    setIsLoading(true);
    const access_token = localStorage.getItem("access_token");
    const response = await IdosListApi(access_token);
    
    if (response?.status === 200) {
      setIdosList(response?.data);
      setFeature(response?.data?.feature);
      setPresent(response?.data?.present);
      setPromotion(response?.data?.promotion);
      setSuccessful(response?.data?.successful);
      setUnsuccessful(response?.data?.unsuccessful);
    } else {
      checkIFLogin()
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTokens();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
       localStorage.removeItem("access_token")
       navigate("/signin");
      // window.location.redirect("/signin")
     }
     return error;
    });
  }
  return (
    <>
      <Layout>
        <div className="flex flex-col gap-5">
          <p className="text-4xl text-zoopad-color">All IDO</p>
          {isLoading ? (
            <ResponseLoader size={100} />
          ) : (
            <div className="flex flex-col gap-6 ">
              <div className="flex flex-row items-center justify-around ">
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Active"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Active")}
                >
                  Active
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Upcoming"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Upcoming")}
                >
                  Upcoming
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Successful"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Successful")}
                >
                  Successful
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Promotions"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Promotions")}
                >
                  Promotions
                </p>
                <p
                  className={`cursor-pointer font-medium ${
                    activeSubHeader === "Unsuccessful"
                      ? "text-zoopad-color"
                      : "text-gray-600"
                  }`}
                  onClick={() => setActiveSubHeader("Unsuccessful")}
                >
                  Unsuccessful
                </p>
              </div>

              <hr />
              {idosList && (
                <div>
                  <IdosTable
                    columns={columns}
                    data={
                      activeSubHeader === "Successful"
                        ? successful
                        : activeSubHeader === "Promotions"
                        ? promotion
                        : activeSubHeader === "Upcoming"
                        ? feature
                        : activeSubHeader === "Unsuccessful"
                        ? unsuccessful
                        : present
                    }
                    // data={data}
                    enableSearch={true}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
