import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IdoCancelApi, IdoDetailApi, IdoPromoteApi, IdoVerifyApi, IdoWithdrawApproval } from "../apis/idoApi";
import BackArrow from "../assets/BackArrow.svg";
import ResponseLoader from "../components/responseLoader";
import CopySvg from "../assets/copy-line-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useToken } from "wagmi";
import axios from "axios";


const variants = [
  "",
  "w-[calc[100%_-_56px]] mx-[28px] my-[28px] rounded-[14px] gradient-box  bg-gradient-1",
  "ido-box",
];

function BoxType({ children, style, variant = 0, className, ...props }) {
  return (
    <div
      style={style}
      className={`
          ${variants[variant]}
          ${className}
      `}
      {...props}
    >
      {children}
    </div>
  );
}

function EditMeme() {
  const [idoDetails, setIdoDetails] = useState({});
  const [copied, setCopied] = useState(false);
  const [valueCopy, setValueCopy] = useState("");
  const [idoOwnerData, setIdoOwnerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [promote, setPromote] = useState();
  const [verifyIdo, setVerifyIdo] = useState();
  const [cancelIdo, setCancelIdo] = useState();
  const [widthdrawApprove, setWidthdrawApprove] = useState();
  const [isApiCalling, setIsApiCalling] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const id = location?.pathname?.split("/")[4];

  // const token = useToken({
  //   address: "0x83F9D9d66ea137B1BbE815aABE15683A1956E260",
  // });
  // console.log(token);

  function copyHandler(text, setCopied, val) {
    setCopied(true);
    setValueCopy(val);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  useEffect(() => {

    fetchDetails();
  }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDetails() {
    setIsLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const result = await IdoDetailApi(accessToken, id);
    if (result?.status === 200) {
      setIdoDetails(result?.data[0]);
      setIdoOwnerData(result?.data[0]?.ido_owner_data[0]);
      setPromote(result?.data[0]?.ido_admin_promote);
      setVerifyIdo(result?.data[0]?.ido_admin_verify);
      setCancelIdo(result?.data[0]?.is_cancelled);
      setWidthdrawApprove(result?.data[0]?.is_widthdraw_approve);
    } else {
      checkIFLogin()
    }
    setIsLoading(false);
  }


  function dateFormat(val) {
    const date = new Date(val * 1000);

    // Get the year, month, and day components
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // JavaScript uses 0-based months, so add 1
    const day = date.getUTCDate();

    // Function to get the month name
    function getMonthName(month) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[month - 1];
    }

    return `${getMonthName(month)} ${day}, ${year}`;
  }

  const handlePromotionStatus = async (val) => {
    const access_token = localStorage.getItem("access_token");
    let data = {
      ido_id: location.pathname.split("/")[4],
      ido_admin_promote: !val,
    };

    const result = await IdoPromoteApi(access_token, data);
    if (result?.status === 200) {
      setPromote(!promote);
      if (!promote) {
        notifyActivity("IDO has been promoted");
      } else {
        notifyActivity("IDO promotion cancelled");
      }
    }
  };
  const notifyActivity = (message) =>
    toast.success(message, {
      autoClose: 1000,
    });

  const handleVerifyStatus = async (val) => {
    const access_token = localStorage.getItem("access_token");
    let data = {
      ido_id: location.pathname.split("/")[4],
      ido_admin_verify: !val,
    };

    const result = await IdoVerifyApi(access_token, data);
    if (result?.status === 200) {
      setVerifyIdo(!verifyIdo);
      if (!verifyIdo) {
        notifyActivity("IDO has been verified");
      } else {
        notifyActivity("IDO verification cancelled");
      }
    }
  };

  const handleWithdrawStatus = async (val) => {
    setIsApiCalling(true)
    const access_token = localStorage.getItem("access_token");
    let data = {
      ido_id: location.pathname.split("/")[4],
      ido_admin_verify: !val,
    };

    const result = await IdoWithdrawApproval(access_token, data);
    if (result?.status === 200) {
      setWidthdrawApprove(!widthdrawApprove);
      if (!widthdrawApprove) {
        notifyActivity("Withdraw approval success");
      } else {
        notifyActivity("Withdraw approval success");
      }
    }
    setIsApiCalling(false)
  }

  const handleCancleStatus = async (val) => {
    setIsApiCalling(true)
    const access_token = localStorage.getItem("access_token");
    let data = {
      ido_id: location.pathname.split("/")[4],
      is_cancelled: !val,
    };

    const result = await IdoCancelApi(access_token, data);
    if (result?.status === 200) {
      setCancelIdo(!cancelIdo);
      if (!cancelIdo) {
        fetchDetails()
        notifyActivity("IDO has been cancel");
      } else {
        notifyActivity("IDO cancelled");
      }
    }
    setIsApiCalling(false)
  };

  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
      localStorage.removeItem("access_token")
       navigate("/signin");
     }
     return error;
    });
  }

  return (
    <>
      <Layout>
        {isLoading ? (
          <ResponseLoader size={100} />
        ) : (
          <>
            <div className="mb-5 cursor-pointer flex justify-between">
              <img
                src={BackArrow}
                alt="Back"
                onClick={() => navigate("/dashboard")}
              />
              <button
                className="py-1 px-3 border-[1px] border-black shadow-lg hover:shadow-sm rounded-lg"
                onClick={() =>
                  navigate(
                    `/dashboard/ido/detail/participants/${
                      location.pathname.split("/")[4]
                    }`,
                    { state: idoDetails?.ido_name }
                  )
                }
              >
                View Participants
              </button>
            </div>
            <div className="w-full border flex flex-col gap-16 border-primary rounded-[18.396px] bg-secondary/80">
              <div className="flex justify-center mt-5">
                <h2 className="text-50-600 text-black leading-[1.25rem] pt-[1rem]">
                  {idoDetails?.ido_name}
                </h2>
              </div>
              <BoxType
                variant={1}
                className={`w-[calc[100%_-_48px]] mx-[24px]`}
              >
                <div className="w-full flex justify-between items-center px-32 pr-14 h-[256px]">
                  <div className="w-[213px] h-[213px]">
                    <img
                      src={`https://${idoDetails?.ido_icon_url}.ipfs.nftstorage.link/`}
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  <div className="max-w-lg flex flex-col gap-4">
                    <h2 className="text-25-600 text-black leading-[1.25rem]">
                      Project Overview
                    </h2>
                    <p className="text-black pt-[20px] ">
                      {" "}
                      {idoDetails?.ido_description}
                    </p>
                    <div className="flex flex-col gap-3">
                      <div className="flex text-18-600 items-center gap-4 ">
                        <input
                          type="checkbox"
                          className="w-4 h-4 cursor-pointer"
                          onChange={() =>
                            handlePromotionStatus(idoDetails?.ido_admin_promote)
                          }
                          checked={promote}
                        />
                        <p>Promote IDO</p>
                      </div>
                      <div className="flex text-18-600 items-center gap-4 ">
                        <input
                          type="checkbox"
                          className="w-4 h-4 cursor-pointer"
                          onChange={() =>
                            handleVerifyStatus(idoDetails?.ido_admin_verify)
                          }
                          checked={verifyIdo}
                        />
                        <p>Verify IDO</p>
                      </div>
                      {/* (idoDetails.is_cancelled) && (idoDetails.ido_end_timestamp < (new Date().getTime() / 1000)) && (idoDetails.ido_completion_percantage !== 100) */}
                      {idoDetails.is_cancelled && (
                        <div className="flex text-18-600 items-center gap-4 relative">
                          <input
                            type="checkbox"
                            className={`w-4 h-4 ${isApiCalling ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'}`}
                            onChange={() =>
                              handleWithdrawStatus(idoDetails?.is_widthdraw_approve)
                            }
                            checked={widthdrawApprove}
                            disabled={widthdrawApprove ? true : false}
                          />
                          <p className={`${isApiCalling && idoDetails.is_cancelled ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'}`}>Withdraw Approve</p>
                          {isApiCalling && idoDetails.is_cancelled && (
                            <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                              <span class="sr-only">Loading...</span>
                            </div>
                          )}

                        </div>
                      )}

                    </div>
                   {(idoDetails.ido_end_timestamp < (new Date().getTime() / 1000)) && (idoDetails.ido_completion_percantage !== 100) && (
                    <div className="flex text-18-600 items-center gap-4 relative">
                        <input
                          type="checkbox"
                          className="w-4 h-4 cursor-pointer"
                          onChange={() =>
                            handleCancleStatus(idoDetails?.is_cancelled)
                          }
                          checked={cancelIdo}
                          disabled={cancelIdo ? true : false}
                        />
                        <p className={`${isApiCalling && !idoDetails.is_cancelled ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'}`}>Cancel IDO</p>
                          {isApiCalling && !idoDetails.is_cancelled && (
                            <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                              <span class="sr-only">Loading...</span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </BoxType>

              <BoxType
                variant={0}
                className={`w-[calc[100%_-_48px]] mx-[24px] mt-[20px] pb-[28px] flex flex-wrap gap-[2rem]`}
              >
                <div className="w-full flex-1">
                  <h2 className="text-20-600 text-black leading-[1.25rem] pb-[20px]">
                    Pool Information
                  </h2>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Pre-sale Rate
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {idoDetails?.ido_token_initial_price}{" "}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Listing Rate{" "}
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {idoDetails?.ido_token_initial_price}{" "}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Total Sale Amount
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {" "}
                      $ {idoDetails?.ido_total_sale_amount || '0'}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Unsold Tokens{" "}
                      
                    </h5>
                    <p className="text-inter-14-500 text-black">Burn</p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Start Time{" "}
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {dateFormat(idoDetails?.ido_listing_timestamp)}{" "}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Total Token Supply{" "}
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {idoDetails?.ido_token_supply}
                    </p>
                  </div>
                </div>
                <div className="w-full flex-1">
                  <h2 className="text-20-600 text-black leading-[1.25rem] pb-[20px]">
                    Token Information
                  </h2>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">Name</h5>
                    <p className="text-inter-14-500 text-black">
                      {" "}
                      {idoDetails?.ido_name}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">Symbol</h5>
                    <p className="text-inter-14-500 text-black">
                      {" "}
                      {idoDetails?.ido_symbol}
                    </p>
                  </div>
                  {/* <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">
                      Token Decimal
                    </h5>
                    <p className="text-inter-14-500 text-black">
                      {" "}
                      {getTokenDetailData?.data &&
                        getTokenDetailData?.data?.decimals}
                    </p>
                  </div> */}

                  <div
                    className={`flex justify-between  py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">Address</h5>
                    <div className="text-inter-14-500  relative text-black truncate flex items-center gap-2 pl-[75px]">
                      <p className="w-[80%] overflow-hidden text-ellipsis">
                        {idoDetails?.ido_token_address}
                      </p>
                      <img
                        className="h-4 w-4 cursor-pointer"
                        src={CopySvg}
                        alt="copy"
                        onClick={() => {
                          copyHandler(
                            idoDetails?.ido_token_address,
                            setCopied,
                            "token"
                          );
                        }}
                      />
                      <p
                        className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
                          copied === true && valueCopy === "token"
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        Copied
                      </p>
                    </div>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">Chain</h5>
                    <p className="text-inter-14-500 text-black">
                      {idoDetails?.ido_chain}
                    </p>
                  </div>

                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">End Time </h5>
                    <p className="text-inter-14-500 text-black">
                      {dateFormat(idoDetails?.ido_end_timestamp)}
                    </p>
                  </div>
                </div>
                <div className="w-full flex-1">
                  <h2 className="text-20-600 text-black leading-[1.25rem] pb-[20px]">
                    Owner Information
                  </h2>
                  <div
                    className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                  >
                    <h5 className="text-inter-14-400 text-black">Name</h5>
                    <p className="text-inter-14-500 text-black">
                      {" "}
                      {idoOwnerData?.name}
                    </p>
                  </div>
                  <div className={`flex justify-between py-[20px]  border-t`}>
                    <h5 className="text-inter-14-400 text-black">
                      Owner Address
                    </h5>
                    <div className="text-inter-14-500 relative flex items-center gap-2 text-black">
                      <p className="w-[80%] overflow-hidden text-ellipsis">
                        {idoOwnerData?.address}
                      </p>

                      <img
                        className="h-4 w-4 cursor-pointer"
                        src={CopySvg}
                        alt="copy"
                        onClick={() => {
                          copyHandler(
                            idoDetails?.ido_token_address,
                            setCopied,
                            "owner"
                          );
                        }}
                      />
                      <p
                        className={`absolute text-center text-xs w-fit right-0 bg-black/90 p-1 rounded-lg text-white/70 ${
                          copied === true && valueCopy === "owner"
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        Copied
                      </p>
                    </div>
                  </div>

                  <div className="py-[20px] border-black_RGB/10 border-t">
                    <h2 className="text-20-600 text-black leading-[1.25rem] pb-[20px]">
                      Social Information
                    </h2>
                    <div
                      className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                    >
                      <h5 className="text-inter-14-400 text-black">
                        Discord url
                      </h5>
                      <Link to={idoOwnerData?.discord_url} target="_blank">
                        <p className="text-inter-14-500 text-black cursor-pointer">
                          {idoOwnerData?.discord_url}
                        </p>
                      </Link>
                    </div>

                    <div
                      className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                    >
                      <h5 className="text-inter-14-400 text-black">
                        Facebook Url{" "}
                      </h5>
                      <Link to={idoOwnerData?.facebook_url} target="_blank">
                        <p className="text-inter-14-500 text-black cursor-pointer">
                          {idoOwnerData?.facebook_url}
                        </p>
                      </Link>
                    </div>

                    <div
                      className={`flex justify-between py-[20px] border-b border-black_RGB/10 border-t`}
                    >
                      <h5 className="text-inter-14-400 text-black">
                        Instagram Url{" "}
                      </h5>
                      <Link to={idoOwnerData?.instagram_url} target="_blank">
                        <p className="text-inter-14-500 text-black cursor-pointer">
                          {idoOwnerData?.instagram_url}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </BoxType>
            </div>
          </>
        )}
      </Layout>
      <ToastContainer />
    </>
  );
}

export default EditMeme;
