import Route from "./routes/routes";

function App() {
  return (
    <>
      <Route />
    </>
  );
}

export default App;
