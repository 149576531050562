import axios from "axios";

export async function IdoInvestmentApi(accessToken) {
  const result = axios
    .get(
      process.env.REACT_APP_ADMIN_API_URL + "/admin/ido/get-system-balance",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}
