import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import { LoginApi } from "../apis/authApi";
// import { LoginAdmin } from "../apis/authAPIs";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoading(true);
    const body = {
      email: email,
      password: password,
    };
    const response = await LoginApi(body);
    if(response) {
      if (response?.status === 200) {
        localStorage.setItem("access_token", response?.data?.access_token);
        navigate("/dashboard");
      } else {
        setError(response?.message);
      }
    }

  };

  return (
    <>
      <div className="flex flex-col justify-center w-screen min-h-screen">
        <div className="max-w-[750px] m-auto flex items-center justify-center w-full gap-3 px-3 py-2 text-xl font-normal text-black bg-white border border-black focus:outline-none rounded-lg">
          <div className="flex flex-col items-center justify-center py-3 bg-white px-7 rounded-3xl">
            <div className="flex justify-center">
              <h4 className="text-4xl text-zoopad-color">
                Login To Zoopad Admin Dashboard
              </h4>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSignIn();
              }}
              className="flex flex-col justify-center mt-7 gap-7 min-w-[350px]"
            >
              <input
                className="flex items-center justify-between w-full gap-3 px-3 py-2 text-xl font-normal text-black bg-white border border-black rounded-lg focus:outline-none"
                title="Enter email"
                type="email"
                category="input"
                value={email}
                placeholder="E-mail"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                disabled={isLoading}
              />
              <input
                className="flex items-center justify-between w-full gap-3 px-3 py-2 text-xl font-normal text-black bg-white border border-black rounded-lg focus:outline-none"
                title="Enter Password"
                type="password"
                category="input"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                disabled={isLoading}
              />
              <div className="flex items-center justify-center mt-7">
                <Button design="login" content="Login" type="submit" />
              </div>
            </form>
            <div className="flex items-center my-5">
              {error && <p className="text-[16px] text-red-600 ">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
