import React from "react";

export default function GlobalFilter({ value, setValue }) {
  return (
    <>
      <input
        type="text"
        className="text-lg font-medium focus:outline-none placeholder:opacity-50 text-zoopad-color min-w-[500px] px-3 py-1 bg-white items-center rounded-lg border border-black  focus:border-[#2564AE]"
        placeholder="Search"
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}
