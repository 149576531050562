import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import { IdoInvestmentApi } from "../apis/balance";
import ResponseLoader from "../components/responseLoader";
import TransactionTable from "../components/Table";
import { columnsIdoParticipants } from "../data/columns";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function Balance() {
  const [zoopadOwner, setZoopadOwner] = useState({});
  const [teamBalance, setTeamBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const columns = useMemo(() => columnsIdoParticipants, []);

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchBalance() {
      setIsLoading(true);
      const accessToken = localStorage.getItem("access_token");
      const result = await IdoInvestmentApi(accessToken);
      if (result?.status === 200) {
        setZoopadOwner(result?.data?.zoopad_owner);
        setTeamBalance(result?.data?.zoopad_team);
        setTransaction(result?.data?.txn_log);
      } else {
        checkIFLogin()
      }
      setIsLoading(false);
    }
    fetchBalance();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
       localStorage.removeItem("access_token")
       navigate("/signin");
     }
     return error;
    });
  }
  return (
    <Layout>
      {isLoading ? (
        <div className="flex justify-center">
          <ResponseLoader size={100} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-12">
            <div className="flex flex-row flex-wrap gap-6 justify-center">
              <div className="border-[1px] border-black rounded-lg p-5 max-w-fit w-full flex flex-col gap-6 ">
                <div className="flex flex-col gap-4">
                  <p className="flex justify-center text-inter-30-600">
                    ZOOPAD OWNER
                  </p>
                  <hr />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row gap-4 items-center">
                    <p className="text-20-600">Wallet Address:</p>{" "}
                    <p className="overflow-hidden text-ellipsis">
                      {zoopadOwner?.zoopad_owner_address}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <p className="text-20-600">Balance:</p>{" "}
                    <p className="overflow-hidden text-ellipsis">
                      {zoopadOwner?.zoopad_owner_balance}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-[1px] border-black rounded-lg p-5 max-w-fit w-full flex flex-col gap-6 ">
                <div className="flex flex-col gap-4">
                  <p className="flex justify-center text-inter-30-600">
                    ZOOPAD TEAM BALANCE
                  </p>
                  <hr />
                </div>

                <div className="flex flex-col gap-3">
                  <div className="flex flex-row gap-4 items-center">
                    <p className="text-20-600">Wallet Address:</p>
                    <p className="overflow-hidden text-ellipsis">
                      {teamBalance?.zoopad_team_address}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <p className="text-20-600">Balance:</p>
                    <p className="overflow-hidden text-ellipsis">
                      {teamBalance?.zoopad_team_balance}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-40-700">ALL Transactions</p>
              <hr />
            </div>
            <TransactionTable
              columns={columns}
              data={transaction}
              enableSearch={true}
            />
          </div>
        </>
      )}
    </Layout>
  );
}

export default Balance;
