import axios from "axios";

// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response.status === 401) {
//    console.log(error);
//    window.location.href = '/signin';
//   // window.location.redirect("/signin")
//  }
//  return error;
// });

export async function IdosListApi(accessToken) {
  const result = axios
    .get(process.env.REACT_APP_ADMIN_API_URL + "/admin/ido/get-all-idos", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error);
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }


      return error;
    });
  return result;
}

export async function IdoDetailApi(accessToken, id) {
  const result = axios
    .get(
      process.env.REACT_APP_ADMIN_API_URL +
        "/admin/ido/get-ido-detail?ido_id=" +
        id,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}

export async function IdoInvestmentApi(accessToken, id) {
  const result = axios
    .get(
      process.env.REACT_APP_ADMIN_API_URL +
        "/admin/ido/get-ido-investment-list?ido_id=" +
        id,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}

export async function IdoPromoteApi(accessToken, data) {
  const result = axios
    .post(
      process.env.REACT_APP_ADMIN_API_URL +
        "/admin/ido/change-promotion-status",
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}

export async function IdoVerifyApi(accessToken, data) {
  const result = axios
    .post(process.env.REACT_APP_ADMIN_API_URL + "/admin/ido/verify-ido", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}

export async function IdoCancelApi(accessToken, data) {
  const result = axios
    .post(process.env.REACT_APP_ADMIN_API_URL + "/admin/ido/cancel-ido", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}

export async function IdoWithdrawApproval(accessToken, data) {
  const result = axios
    .post(process.env.REACT_APP_ADMIN_API_URL + "/admin/ido/set-stable-withdraw-approval", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
      }
      return error;
    });
  return result;
}
