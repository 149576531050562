import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import ResponseLoader from "../components/responseLoader";
import MemeTokenListTable from "../components/Table";
import { columsMemeTokenList } from "../data/columns";
import { MemeTokensListApi } from "../apis/tokens";
import { useNavigate } from "react-router-dom";
import axios from "axios";


function MemeToken() {
  const [isLoading, setIsLoading] = useState(false);
  const [memeTokenListsData, setMemeTokenListsData] = useState([]);
  const columns = useMemo(() => columsMemeTokenList, []);
  const [memeTokenDependency, setMemeTokenDependency] = useState(false);
  // const data = useMemo(() => TokenListData, []);

  const navigate = useNavigate()


  const getAllTokens = async () => {
    setIsLoading(true);
    const access_token = localStorage.getItem("access_token");
    const response = await MemeTokensListApi(access_token);
    if (response?.status === 200) {
      setMemeTokenListsData(response?.data);
    }
    // setTokenListsData(response || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTokens();
  }, [memeTokenDependency]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkIFLogin()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function checkIFLogin() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
      localStorage.removeItem("access_token")
       navigate("/signin");
     }
     return error;
    });
  }
  
  return (
    <Layout>
      <div className="flex flex-col gap-5">
        <p className="text-4xl text-zoopad-color"> Meme Tokens </p>
        {isLoading ? (
          <ResponseLoader size={100} />
        ) : (
          <MemeTokenListTable
            columns={columns}
            data={memeTokenListsData}
            // data={data}
            memeTokenDependency={setMemeTokenDependency}
            enableSearch={true}
          />
        )}
      </div>
    </Layout>
  );
}

export default MemeToken;
