import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ fromRoute, children }) {
  const token = localStorage.getItem("access_token");
  if (fromRoute === "signin" && !token) {
    return children;
  } else if (fromRoute === "signin") {
    return <Navigate to="/dashboard" replace />;
  } else if (!token) {
    return <Navigate to="/signin" replace />;
  } else return children;
}
